import React, { useState, useEffect } from 'react';
import './Header.scss';
import MenuModal from '../menuModal/MenuModal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar';
import { Modal, Form } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FiMoreVertical } from "react-icons/fi";
import { sp } from '@pnp/sp';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import { SPFetchClient } from "@pnp/nodejs";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import helpers from '../helpers';
import AboutContact from '../aboutContact/aboutContact';
import CustomWebpart from '../customWebpart/CustomWebpart';
import EditMainMenu from '../editMainMenu/EditMainMenu';
import { useHistory, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import QuickLinks from '../quickLinks/QuickLinks';
/*import JoditWebpart from '../joditWebpart/jodit';*/
import YammerNative from '../yammerNative/YammerNative';

interface WelcomeProps {
    editMode: boolean,
    setEditMode: any;
}



const Header = (params: any) => {

    useEffect(() => {
        getDefaultMenuItems();
        getAllQuickLinks();
    }, []);

    interface IResponse extends Object {
        name: string,
        id: string,
        link: string,
        desc: string,
        order: any
    }

    const response = [
        {
            name: "HomeTest",
            id: "A1",
            link: "dashboard",
            desc: "",
            order: 1
        },
        {
            name: "Announcement",
            id: "A2",
            link: "announcement",
            desc: "",
            order: 2
        },
        {
            name: "Events",
            id: "A3",
            link: "events",
            desc: "",
            order: 3
        },
        {
            name: "Leadership & Team",
            id: "A4",
            link: "leadership-and-team",
            desc: "",
            order: 4
        },

    ]



    const myLinksResponse = [
        {
            category: "Metocean",
            id: "l0",
            order: 0,
            subcategory: [
                {
                    id: 'sub2',
                    title: 'Learning and Development',
                    links: [
                        {
                            title: "Principal Technical Experts",
                            url: "https://www.PrincipalTechnicalExperts.com/",
                            id: "s0",
                            order: 0
                        },

                    ]
                },
                {
                    id: 'sub1',
                    title: 'Knowledge Networks and Collaboration',
                    links: [
                        {
                            title: "Principal Technical Experts",
                            url: "https://www.PrincipalTechnicalExperts.com/",
                            id: "s0",
                            order: 0
                        },
                        {
                            title: "Experts",
                            url: "https://www.Experts.com/",
                            id: "s1",
                            order: 1
                        },
                        {
                            title: "Articles Experts",
                            url: "https://www.Articles Experts.com/",
                            id: "s2",
                            order: 2
                        },
                        {
                            title: "Standard and Guidelines",
                            url: "https://www.Articles Experts.com/",
                            id: "s3",
                            order: 3
                        },
                        {
                            title: "Tools",
                            url: "https://www.Articles Experts.com/",
                            id: "s4",
                            order: 4
                        },
                        {
                            title: "Knowledge Repository",
                            url: "https://www.Articles Experts.com/",
                            id: "s5",
                            order: 5
                        },

                    ]
                },

            ]
        },
        {
            category: "Learning and Development",
            id: "l1",
            order: 1,
            subcategory: [{
                id: 'sub21',
                title: 'Tools',
                links: [
                    {
                        title: "Principal Technical Experts",
                        url: "https://www.PrincipalTechnicalExperts.com/",
                        id: "s25"
                    },
                    {
                        title: "Experts",
                        url: "https://www.Experts.com/",
                        id: "s26"
                    },
                    {
                        title: "Articles Experts",
                        url: "https://www.Articles Experts.com/",
                        id: "s27"
                    }
                ]
            }]
        },
        {
            category: "Tools",
            id: "l2",
            order: 2,
            subcategory: [{
                id: 'sub31',
                title: 'Tools',
                links: [
                    {
                        title: "Principal Technical Experts",
                        url: "https://www.PrincipalTechnicalExperts.com/",
                        id: "s35"
                    },
                    {
                        title: "Experts",
                        url: "https://www.Experts.com/",
                        id: "s36"
                    },
                    {
                        title: "Articles Experts",
                        url: "https://www.Articles Experts.com/",
                        id: "s37"
                    }
                ]
            }]
        }
    ];


    let categoriesFromMyLinks: any = [];

    myLinksResponse.forEach(o => {
        categoriesFromMyLinks.push({ category: o.category, id: o.id });
    })

    let location = useLocation();
    const Url_Paramater = location.pathname.split("/")[1];
    const PAGES_LIBRARY_URL = helpers.hostweburl + "/Pages/Forms/AllItems.aspx";
    const HELP_PAGE_URL = helpers.sitecollectionurl + "Pages/HelpVideos.aspx";
    const YAMMER_FEEDBACK_URL = "https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIyMzQxNDYwMzc3NiJ9";
    //#region Storing the state values
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [dialogAction, setDialogAction] = useState<string>('edit')
    const [links, setLinks] = useState<IResponse[]>(response);
    const [myLinksCategories, setMyLinksCategories] = useState(categoriesFromMyLinks);
    const [myLinks, setMyLinks] = useState(myLinksResponse);
    //Used states for Mega-Menu
    //const [MenuLinks, setMenuLinks] = useState([{category:"", links: []}]);
    const [MenuLinks, setMenuLinks] = useState(params.MenuLinks);
    const [Mymainlinks, setMymainlinks] = useState();
    const [aboutShow, setAboutShow] = useState<boolean>(false);
    const [editMenuShow, setEditMenuShow] = useState<boolean>(false);
    const [showCustomWP, setShowCustomWP] = useState<boolean>(false);
    const [showDelete, setshowDelete] = useState<boolean>(false);
    //#endregion
    const [modalShow, setModalShow] = React.useState(false);
    const history = useHistory();
    const [defaultMenuItems, setDefaultMenuItems] = useState([]);
    const [quickLinks, setQuickLinks] = useState([]);
    const [isLoading, setLoading] = useState(true);

    return (
        <div id="header">
            <Helmet>
                <title>{params.isSiteName ? params.isSiteName : "SPoA 3.0"}</title>
            </Helmet>
            {/* <MenuModal showDialog={showDialog} handleClose={handleClose} dialogType={dialogAction} categories={myLinksCategories} links={myLinks} linksDelete={myLinksResponseDelete} /> */}
            <Container>
                <Row>
                    <Col>
                        {/*  Branding and Search Bar Begin */}
                        {/* <Navbar className="justify-content-between"> */}

                        <Navbar className={`justify-content-between${params.editMode}`}>
                            <Link className="logo-and-title" to={'/' + Url_Paramater + "/dashboard"}>
                                <h1>{params.isSiteName}</h1>
                            </Link>
                            <Form inline className='search-spoa-box' onSubmit={(e) => onFormSubmit(e)}>
                                <FormControl type="text" placeholder="Search" className="search-form-txt-box mr-sm-2 min-search" id="header-search-input" onFocus={() => {
                                    document.getElementById("header-search-input").classList.remove('min-search');
                                }}
                                    onBlur={() => {
                                        document.getElementById("header-search-input").classList.add('min-search');
                                    }}
                                />
                                <Button onClick={onFormSubmit} variant="secondary" className="search-icon-btn"> <FaSearch className="search-icon" /> </Button>

                                <div className="user-info">
                                    <a href="https://eu001-sp.shell.com/sites/AAAAA9133/default.aspx" target="_blank">All SPoAs</a>
                                    {/* <a>FAQ's</a>*/}
                                    <a role="button" onClick={() => { setAboutShow(true); sendToAppInsights("About and Contact"); }}>About &amp; Contact</a>
                                    <a href="https://eu001-sp.shell.com/sites/aaaab1732/pages/spoa-support.aspx" target="_blank">Support</a>
                                    <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
                                    <AboutContact siteName={params.isSiteName} isModerator={params.isModerator} editMode={params.editMode} show={aboutShow} onHide={() => setAboutShow(false)} />
                                    <CustomWebpart show={showCustomWP} getCustomWebpart={params.getCustomWebpart} onHide={() => setShowCustomWP(false)} />
                                    {/*<CKEditor show={showDelete} onHide={() => setshowDelete(false)} />*/}
                                    {/*<JoditWebpart show={showDelete} onHide={() => setshowDelete(false)} />*/}
                                    <YammerNative show={showDelete} onHide={() => setshowDelete(false)} />
                                    <div className="user-pic">
                                        <Dropdown drop={'left'}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {/* <img className='userIMG'  src='https://randomuser.me/api/portraits/men/0.jpg' alt='Dummy user' /> */}
                                                <img className='userIMG' src={`${helpers.sitecollectionurl}/_layouts/15/userphoto.aspx?size=L&accountname=${params.isEmail}`} alt={params.isEmail}></img>
                                            </Dropdown.Toggle>

                                            {params.isModerator ?
                                                <Dropdown.Menu>
                                                    {!params.editMode ?
                                                        <Dropdown.Item className='edit-mode-link' onClick={() => { params.setEditMode(true); params.getwebpartsVisibility(); }}>Edit Mode</Dropdown.Item> :
                                                        <Dropdown.Item className='edit-mode-link' onClick={() => { params.setEditMode(false); params.getwebpartsVisibility(); }}>Normal Mode</Dropdown.Item>
                                                    }
                                                    <Dropdown.Item className='create-page-link' onClick={() => window.open(PAGES_LIBRARY_URL)}>Sharepoint Pages</Dropdown.Item>
                                                    {
                                                        params.response.length <= 7 ? <Dropdown.Item className='create-page-link' onClick={() => setShowCustomWP(true)}>Custom Webpart</Dropdown.Item>
                                                            : <Dropdown.Item className='create-page-link' onClick={() => setModalShow(true)}>Custom Webpart</Dropdown.Item>
                                                    }
                                                    <Dropdown.Item className='create-page-link' onClick={params.onResetLayout}>Reset Layout</Dropdown.Item>
                                                    <Dropdown.Item className='create-page-link' onClick={() => window.open(HELP_PAGE_URL, "WindowName", "noopener")}>Help Videos</Dropdown.Item>
                                                    <Dropdown.Item className='create-page-link' onClick={() => window.open(YAMMER_FEEDBACK_URL, "WindowName", "noopener")}>Share your feedback</Dropdown.Item>
                                                    {/*<Dropdown.Item className='create-page-link' onClick={() => setshowDelete(true)}>CK Editor</Dropdown.Item>*/}
                                                    {/* <Dropdown.Item className='create-page-link' onClick={() => setshowDelete(true)}>JODIT Editor</Dropdown.Item>*/}
                                                    {/* <Dropdown.Item className='create-page-link' onClick={() => setshowDelete(true)}>Yammer native</Dropdown.Item>*/}
                                                </Dropdown.Menu>
                                                : ""}
                                        </Dropdown>

                                    </div>
                                </div>

                            </Form>
                        </Navbar>
                        {/*  Branding and Search Bar End */}
                        {!isLoading ?
                            <div>
                                {/* Menu Begin */}
                                <Navbar expand='lg' className={params.editMode ? 'edit-mode' : ''}>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="mr-auto">
                                            <div>
                                                <li className="mega-drop-down">
                                                    {defaultMenuItems[0].Visible && <NavLink activeClassName={'active'} className="nav-link" to={defaultMenuItems[0].Title}><span className="customlinks_span">{defaultMenuItems[0].Value}</span></NavLink>}
                                                    {defaultMenuItems[0].Title == "links" && <ImportantLinks />}
                                                </li>
                                            </div>
                                            <div>
                                                <li className="mega-drop-down">
                                                    {defaultMenuItems[1].Visible && <NavLink activeClassName={'active'} className="nav-link" to={defaultMenuItems[1].Title}><span className="customlinks_span">{defaultMenuItems[1].Value}</span></NavLink>}
                                                    {defaultMenuItems[1].Title == "links" && <ImportantLinks />}
                                                </li>
                                            </div>
                                            <div>
                                                <li className="mega-drop-down">
                                                    {defaultMenuItems[2].Visible && <NavLink activeClassName={'active'} className="nav-link" to={defaultMenuItems[2].Title}><span className="customlinks_span">{defaultMenuItems[2].Value}</span></NavLink>}
                                                    {defaultMenuItems[2].Title == "links" && <ImportantLinks />}
                                                </li>
                                            </div>
                                            <div>
                                                <li className="mega-drop-down">
                                                    {defaultMenuItems[3].Visible && <NavLink activeClassName={'active'} className="nav-link" to={defaultMenuItems[3].Title}><span className="customlinks_span">{defaultMenuItems[3].Value}</span></NavLink>}
                                                    {defaultMenuItems[3].Title == "links" && <ImportantLinks />}
                                                </li>
                                            </div>
                                            <div>
                                                <li className="mega-drop-down">
                                                    {defaultMenuItems[4].Visible && <NavLink activeClassName={'active'} className="nav-link" to={defaultMenuItems[4].Title}><span className="customlinks_span">{defaultMenuItems[4].Value}</span></NavLink>}
                                                    {defaultMenuItems[4].Title == "links" && <ImportantLinks />}
                                                </li>
                                            </div>
                                            <div>
                                                <li className="mega-drop-down">
                                                    {defaultMenuItems[5].Visible && <NavLink activeClassName={'active'} className="nav-link" to={defaultMenuItems[5].Title}><span className="customlinks_span">{defaultMenuItems[5].Value}</span></NavLink>}
                                                    {defaultMenuItems[5].Title == "links" && <ImportantLinks />}
                                                </li>
                                            </div>
                                            {quickLinks.length > 0 && <QuickLinks editMode={params.editMode} quickLinks={quickLinks} />}
                                            {!params.editMode ? "" : <Link to="#" className="editTopTabsLink" onClick={() => setEditMenuShow(true)} >Edit Menu</Link>}
                                            <EditMainMenu show={editMenuShow} EditMode={params.editMode} onHide={() => setEditMenuShow(false)} defaultMenuItems={defaultMenuItems} getDefaultMenuItems={getDefaultMenuItems} quickLinks={quickLinks} getAllQuickLinks={getAllQuickLinks} />
                                        </Nav>

                                    </Navbar.Collapse>
                                </Navbar>

                                {/* Menu End  */}
                            </div>

                            :
                            <div className="card-loading">
                                <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                    <Skeleton height={40} width={1060}></Skeleton>
                                </SkeletonTheme>
                            </div>
                        }

                    </Col>
                </Row>
            </Container>
        </div >
    )

    function ImportantLinks() {
        return (
            <div className="animated fadeIn mega-menu">
                <div className="mega-menu-wrap">
                    <div className="mega-menu-viewlinks">
                        <div className="importantlinks-text"> Important Links:</div>
                        <Link className={location.pathname.indexOf("links") > -1 && "view-link-disabled"} to={'/' + Url_Paramater + "/links"}>View all links</Link>
                    </div>
                    <div className="row">
                        {
                            params.MenuLinks.empty ? <div className='no-links-message'>No links has been added to this Community.</div> :

                                Object.keys(params.MenuLinks).length >= 1 ?
                                    //Looping the object to load the data in mega-menu
                                    Object.keys(params.MenuLinks).map(function (obj: any) {
                                        let moreLinksUrl;
                                        if (params.MenuLinks[obj].MId) {
                                            moreLinksUrl = '/' + Url_Paramater + "/links#" + params.MenuLinks[obj].MId;
                                        }
                                        else {
                                            moreLinksUrl = '/' + Url_Paramater + "/links";
                                        }
                                        if (params.MenuLinks[obj].category != "") {
                                            let sortedLinks = params.MenuLinks[obj].links.sort((a, b) => parseFloat(a.Order) - parseFloat(b.Order));
                                            return <div className="col-md-4" key={obj}>
                                                <h4 className="row mega-title"> {decodeURIComponent(obj)} </h4>
                                                {<ul className="stander">
                                                    {sortedLinks.map((object: any, i: any) => {
                                                        return <li key={i} > <span onClick={() => { window.open(object.URL) }}>{object.Title}</span></li>
                                                    })
                                                    }
                                                    <li> <Link className="more-links-mega" to={moreLinksUrl}>more links</Link> </li>
                                                </ul>}
                                            </div>
                                        }
                                    })
                                    :
                                    <div className="Header-Links-loading">
                                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                            <Skeleton height={300} width={200}></Skeleton>
                                        </SkeletonTheme>
                                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                            <Skeleton height={300} width={200}></Skeleton>
                                        </SkeletonTheme>
                                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                            <Skeleton height={300} width={200}></Skeleton>
                                        </SkeletonTheme>
                                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                            <Skeleton height={300} width={200}></Skeleton>
                                        </SkeletonTheme>
                                        <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                                            <Skeleton height={300} width={200}></Skeleton>
                                        </SkeletonTheme>
                                    </div>
                        }
                    </div>
                </div>
            </div>
        )
    }


    function MyVerticallyCenteredModal(props) {
        return (
            <Modal id="custom-webpart-alert"
                {...props}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You cannot create more than 8 webparts !
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //#region  Getting the data from links list based on main link categories response 
    //    function getData(myMainLinksCall: any){
    //         let setLinks_val:any = {};
    //         //Creating bacth to execute the multiple calls
    //         let batch = sp.createBatch();
    //         const columns = "ID,URL,Title,SpoALinkType,SpoAOrder,MainLinkCategory/Title";
    //         const expandCol = "MainLinkCategory";
    //         if(myMainLinksCall) {
    //             for(let i = 0; i< myMainLinksCall.length; i++) {
    //                 sp.web.lists.getByTitle("Links").items.inBatch(batch).select(columns).expand(expandCol).
    //                 filter("(MainLinkCategory/Title eq '"+myMainLinksCall[i].Title+"') and (SpoAHidden eq 0) and (SpoALinkType eq 'ShowOnHomePage')").
    //                 orderBy("SpoAOrder").get().then((itemslink: any[]) => {
    //                     //Creating an array to bind the data in mega-menu
    //                     itemslink.map( o => {
    //                         if(!setLinks_val[o.MainLinkCategory.Title]) { 
    //                             setLinks_val[o.MainLinkCategory.Title] = {};
    //                             setLinks_val[o.MainLinkCategory.Title]["category"] =  o.MainLinkCategory.Title; 
    //                             setLinks_val[o.MainLinkCategory.Title]["id"] =  o.ID; 
    //                             setLinks_val[o.MainLinkCategory.Title]["links"] = [] 
    //                         }
    //                         setLinks_val[o.MainLinkCategory.Title]["links"].push({ URL: o.URL.Url, Title: o.Title}) 
    //                     })
    //                  })
    //         }
    //         //Loading the batch data after final response
    //         batch.execute().then( ()=> {

    //             let obj:any = setLinks_val; 
    //             setMenuLinks(obj);
    //         })
    //     }

    //     }
    // //#endregion

    function LayoutMode() {
        return (
            <Dropdown.Item className='edit-mode-link' onClick={() => {
                params.setEditMode(true);
                document.body.classList.add('zoom-out');
            }}>Layout Mode</Dropdown.Item>
        )
    }

    function handleClose(item: any) {
        if (item) {
            // let menus = links;
            // menus.splice(item.order - 1, 0, item);
            // setLinks(menus);

            let menus = myLinks;

            menus.map((e: any) => {

                if (e.id === item.category) {
                    e.links.push({ title: item.caption, url: item.menuURL })
                }

            });

            setMyLinks(menus);
        }

        setShowDialog(false);
    }

    function Customlinkpopupaction(action: string) {
        setDialogAction(action);
        setShowDialog(true);
    }

    function onFormSubmit(e) {
        e.preventDefault();
        const txtBox = document.querySelector(".search-form-txt-box") as HTMLInputElement;
        if (txtBox.value !== "") {
            history.push('search')
        }
    }
    function getDefaultMenuItems() {
        let temp = [];
        helpers.getAllDataSorted("Quick Links", ((menuItems: any[]) => {
            menuItems.forEach((item: any) => {
                temp.push({ "Title": item.Title, "Value": item.Value, "ID": item.Id, "Visible": item.isVisible })
            })
            setDefaultMenuItems(temp);
            setLoading(false);
        }), ["Title", "isDefault", "isVisible", "Value", "Id", "SpoAOrder"], [], ["isDefault eq 1"], ["SpoAOrder"]);
    }

    function getAllQuickLinks() {
        helpers.getAllDataSorted("Quick Links", ((quickLinks: any[]) => {
            setQuickLinks(quickLinks);
        }), ["Title", "LinkURL", "Id", "isVisible", "SpoAOrder"], [], ["isDefault eq 0"], ["SpoAOrder"]);
    }


    function sendToAppInsights(webpartname) {
        params.AppInsights.trackEvent('SPOA3.0', {
            PageUrl: window.location.href,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: webpartname + ' Web Part',
            WebPartHeader: webpartname,
            UserEmail: params.isEmail
        });
        params.AppInsights.flush();
    }
}

export default Header;