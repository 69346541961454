/*Dev Environment we need to import the below modules*/
import { sp as sppnp } from '@pnp/sp';
import { SPFetchClient } from "@pnp/nodejs";
/*End of Dev setup*/

/* PROD Environment we need to import the below modules*/
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";
import { Switch, useLocation } from 'react-router-dom';
import * as $ from "jquery";

//import { AdalFetchClient } from "@pnp/nodejs";
/*End of PROD setup*/

/*URL Setup for Dev*/
// Set URL globally
const url = "https://shelldevelopment.sharepoint.com/sites/SPODA0019/CSO";
/*End of URL Setup for Dev*/

/*PROD URL Setup*/
//let appweburl;
//let hostweburl;
//let sitecollectionurl;
//let siteSiteAssetsURL;
/*End of URL Setup for PROD*/
//let location = useLocation();
// Helpers util for all the calls    
const helpers = {

    // Get data based on preference (All data, Specific columns, Expanded clumns)
    // For all columns, last two parameters are optional and not required
    // For specific columns, 3rd parameter is required to be passed as array of strings (refer to banner.tsx)
    // Pass a callback function as the 2nd parameter to process the data after being fetched from list
    // For expanded columns, 3rd and 4th parameters need to be passed as per requirement, 4th column is the array of strings with names of columns which have to be expanded
    // Refer to banner.tsx for detailed understanding  

    /*Starting of Pre-Prod URL*/
    //sitecollectionurl: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment.sharepoint.com/sites/SPODA0019/" :
    //    "https://eu023-sp.shell.com/sites/SPOAA0668/",
    //appweburl: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment-9efe0c004e79f3.sharepoint.com/sites/SPODA0019/KMSHELLSPoA30" :
    //    "https://ShellCorp-2245806a12ec89.sharepoint.com/sites/SPOAA0668/KMShellSPoA30DevPHAApp",
    //hostweburl: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment.sharepoint.com/sites/SPODA0019/" + window.location.hash.split("/")[1] :
    //    "https://eu023-sp.shell.com/sites/SPOAA0668/" + window.location.hash.split("/")[1],
    //siteSiteAssetsURL: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "/sites/SPODA0019/" + window.location.hash.split("/")[1] + "/SiteAssets/" :
    //    "/sites/SPOAA0668/" + window.location.hash.split("/")[1] + "/SiteAssets/",
    //siteOrigin: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment.sharepoint.com" :
    //    "https://eu023-sp.shell.com",
    //siteAppSearchURL: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment.sharepoint.com" :
    //    "https://eu023-sp.shell.com",
    //krDocURL: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment.sharepoint.com" :
    //    "https://eu023-sp.shell.com/sites/SPOAA0033/",
    //taxonomyURLAppWebURL: "https://ShellCorp-2245806a12ec89.sharepoint.com/sites/SPOAA0668/",
    //taxonomyURL: "/sites/SPOAA0668/TaxonomyTerms/taxonomy.txt",
    ////appinsightsinstrumentationKey: "7ca2c8fc-91e2-4ba0-a164-37751d079dc1",
    //appinsightsinstrumentationKey: "8faf6b39-49d3-45d5-98cc-62474d3d5f4a",
    //rootSiteCollection: "https://eu023-sp.shell.com/sites",
    //EESiteCollectionUrl:"https://eu023-sp.shell.com/sites/SPOAA0041",

    /*End of Pre-Prod URL*/

    /*Starting of UAT URL*/
    //sitecollectionurl: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment.sharepoint.com/sites/SPODA0019" :
    //    "https://eu023-sp.shell.com/sites/SPOAA0027/",
    //appweburl: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment-9efe0c004e79f3.sharepoint.com/sites/SPODA0019/KMSHELLSPoA30" :
    //    "https://ShellCorp-82220dd043519a.sharepoint.com/sites/SPOAA0027/KMSHELLSPoA30",
    //hostweburl: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment.sharepoint.com/sites/SPODA0019/" + window.location.hash.split("/")[1] :
    //    "https://eu023-sp.shell.com/sites/SPOAA0027/" + window.location.hash.split("/")[1],
    //siteSiteAssetsURL: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "/sites/SPODA0019/" + window.location.hash.split("/")[1] + "/SiteAssets/" :
    //    "/sites/SPOAA0027/" + window.location.hash.split("/")[1] + "/SiteAssets/",
    //siteOrigin: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment.sharepoint.com" :
    //    "https://eu023-sp.shell.com",
    //siteAppSearchURL: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment.sharepoint.com" :
    //    "https://eu001-sp.shell.com",
    //krDocURL: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
    //    "https://shelldevelopment.sharepoint.com" :
    //    "https://eu001-sp.shell.com/sites/AAAAB2112/",
    //taxonomyURLAppWebURL: "https://ShellCorp-82220dd043519a.sharepoint.com/sites/SPOAA0027/",
    //taxonomyURL: "/sites/SPOAA0027/TaxonomyTerms/taxonomy.txt",
    //appinsightsinstrumentationKey: "8faf6b39-49d3-45d5-98cc-62474d3d5f4a",
    //rootSiteCollection: "https://eu023-sp.shell.com/sites",
    //EESiteCollectionUrl:"https://eu023-sp.shell.com/sites/SPOAA0041",

    /*End of UAT URL*/

    /*Starting of PROD URL*/
    sitecollectionurl: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
        "https://shelldevelopment.sharepoint.com/sites/SPODA0019" :
        "https://eu001-sp.shell.com/sites/AAAAA9133/",
    appweburl: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
        "https://shelldevelopment-9efe0c004e79f3.sharepoint.com/sites/SPODA0019/KMSHELLSPoA30" :
        "https://ShellCorp-2c2a0f301f785d.sharepoint.com/sites/AAAAA9133/KMShellSPoA30Prod",
    hostweburl: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
        "https://shelldevelopment.sharepoint.com/sites/SPODA0019/" + window.location.hash.split("/")[1] :
        "https://eu001-sp.shell.com/sites/AAAAA9133/" + window.location.hash.split("/")[1],
    siteSiteAssetsURL: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
        "/sites/SPODA0019/" + window.location.hash.split("/")[1] + "/SiteAssets/" :
        "/sites/AAAAA9133/" + window.location.hash.split("/")[1] + "/SiteAssets/",
    siteOrigin: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
        "https://shelldevelopment.sharepoint.com" :
        "https://eu001-sp.shell.com",
    siteAppSearchURL: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
        "https://shelldevelopment.sharepoint.com" :
        "https://eu001-sp.shell.com",
    krDocURL: window.location.href.toLowerCase().indexOf('localhost') !== -1 ?
        "https://shelldevelopment.sharepoint.com" :
        "https://eu001-sp.shell.com/sites/AAAAB2112/",
    taxonomyURLAppWebURL: "https://ShellCorp-2c2a0f301f785d.sharepoint.com/sites/AAAAA9133/",
    taxonomyURL: "/sites/AAAAA9133/TaxonomyTerms/taxonomy.txt",
    //appinsightsinstrumentationKey: "911b7671-75a1-40e9-9974-43901ad40b7c",
    appinsightsinstrumentationKey: "57570c1d-f544-45ab-b8c7-adfc79144c31",
    rootSiteCollection: "https://eu001-sp.shell.com/sites",
    EESiteCollectionUrl:"https://eu001-sp.shell.com/sites/AAAAB1732",

    /*End of PROD URL*/

    getAllData: function (listName: string, _callback: any, columns?: any, expandCol?: any, order?: any, filters?: any, top?: any): any {

        if (top === undefined) {
            top = "";
        }
        if (filters === undefined) {
            filters = "";
        }
        if (order === undefined) {
            order = "Id";
        }
        window['SP_Propertyweb'].lists.getByTitle(listName).items.select(columns).expand(expandCol).orderBy(order).filter(filters).top(4999).get().then((items: any[]) => {
            _callback(items);
        }).catch(e => {
            UserAutheticateCall();
        });
    },



    getUser: function (_callback: any): any {
        window['SP_Propertyweb'].currentUser.get().then((r: any) => {
            _callback(r);
        }).catch(e => {
            UserAutheticateCall();
        });
    },

    getUserGroupdetails: function (Id: string, _callback: any): any {
        window['SP_Propertyweb'].getUserById(parseInt(Id, 10)).groups.get().then((r: any) => {
            _callback(r);
        }).catch(e => {
            UserAutheticateCall();
        });
    },
    getAllDatasortdesc: function (listName: string, _callback: any, columns?: any, expandCol?: any, order?: any, filters?: any, top?: any): any {

        if (top === undefined) {
            top = "";
        }
        if (filters === undefined) {
            filters = "";
        }
        if (order === undefined) {
            order = "Id";
        }

        window['SP_Propertyweb'].lists.getByTitle(listName).items.select(columns).expand(expandCol).orderBy(order, false).filter(filters).top(top).get().then((items: any[]) => {
            _callback(items);
        }).catch(e => {
            UserAutheticateCall();
        });
    },

    getUserNames: function (_callback: any, name: string) {
        window['SP_Propertyweb'].siteUsers.top(499).filter("substringof('" + name + "',Title)").get().then(function (data) {
            _callback(data);
        }).catch(e => {
            UserAutheticateCall();
        });
    },

    getWebEnsureUser: function (_callback: any, name: string) {
        window['SP_Propertyweb'].ensureUser(name).then(function (data) {
            _callback(data);
        }).catch(e => {
            UserAutheticateCall();
        });
    },

    getAllDataSorted: function (listName: string, _callback: any, columns?: any, expandCol?: any, filterCol?: any, orderbyCol?: any): any {
        let anyData: any[] = [];
        if (filterCol === undefined) {
            filterCol = ""
        }
        //get items
        window['SP_Propertyweb'].lists.getByTitle(listName).items.select(columns).expand(expandCol).filter(filterCol).orderBy(orderbyCol).get().then((items: any[]) => {
            _callback(items);
        }).catch(e => {
            UserAutheticateCall();
        });
    },

    updateData: function (listName: string, data: any, id: string, _callback?: any) {
        window['SP_Propertyweb'].lists.getByTitle(listName).items.getById(parseInt(id, 10)).update(
            data
        ).then(i => {
            _callback();
        }).catch(e => {
            UserAutheticateCall();
        });
    },

    deletelinks: function (listName: string, _callback: any, ID?: any,): any {
        window['SP_Propertyweb'].lists.getByTitle(listName).items.getById(ID).recycle().then(_ => { _callback() }).catch(e => {
            UserAutheticateCall();
        });
    },
    deleteItemByID: function (listName: string, ID?: any, _callback?: any) {
        window['SP_Propertyweb'].lists.getByTitle(listName).items.getById(ID).recycle().then((iar: any) => {
            _callback(iar);
        }).catch(e => {
            UserAutheticateCall();
        });
    },
    getAllChoices: function (listName: string, fieldName: string, _callback?: any) {
        window['SP_Propertyweb'].lists.getByTitle(listName).fields.top(4999).getByInternalNameOrTitle(fieldName).get().then(function (f) {
            _callback(f.Choices);
        }).catch(e => {
            UserAutheticateCall();
        });
    },
    addData: function (listName: string, data: any, _callback?: any) {
        window['SP_Propertyweb'].lists.getByTitle(listName).items.add(data).then((iar: any) => {
            _callback(iar);
        }).catch(e => {
            UserAutheticateCall();
        });
    },
    deleteCustomExpert: function (listName: string, ID?: any, _callback?: any) {
        window['SP_Propertyweb'].lists.getByTitle(listName).items.getById(ID).delete().then((iar: any) => {
            _callback(iar);
        }).catch(e => {
            UserAutheticateCall();
        });
    },
    updateCustomExpert: function (listName: string, data: any, ID: any, _callback: any) {
        window['SP_Propertyweb'].lists.getByTitle(listName).items.getById(ID).update(data).then((iar: any) => {
            _callback(iar);
        }).catch(e => {
            UserAutheticateCall();
        });
    },
    getSearchresult: function (listName: string, _callback?: any, columns?: any, filterCol?: any) {
        window['SP_Propertyweb'].lists.getByTitle(listName).items.select(columns).filter(filterCol).get().then((items: any[]) => {
            _callback(items);
        }).catch(e => {
            UserAutheticateCall();
        });
    },
    getKRListView: function (listName: string, _callback?: any, columns?: any, filterCol?: any) {
        window['SP_Propertyweb'].lists.getByTitle(listName).items.select(columns).filter(filterCol).get().then((items: any[]) => {
            _callback(items);
        }).catch(e => {
            UserAutheticateCall();
        });
    },

    createMarkup: function (value: any) {
        return { __html: value };
    },
    searchCallBacks: {
        ON_CONLCUDED_DISCUSSIONS: "onConcludedDiscussions",
        ON_FORUM_NAME: "onForumName",
        ON_PRACTICE_WORTH_REPLICATING: "onPracticeWorthReplicating",
        ON_LESSONS_LEARNED: "onLessonsLearned",
        ON_LEARNING_FROM_INCIDENT: "onLearningFromIncident",
        ON_LEARNING_EXPERT_APPROVED: "onLearningExpertApproved",
        ON_LEARNING_SUBMITTED: "onLearningSubmitted",
        ON_ARTICLE_EXPERT_APPROVED: "onArticleExpertApproved",
        ON_ARTICLE_PEER_REVIEWED: "onArticlePeerReviewed",
        ON_ARTICLE_USER_CONTRIBUTED: "onArticleUserContributed",
        ON_SPOA_ANNOUNCEMENTS: "onSPoAAnnouncements",
        ON_SPOA_LEADERSHIPTEAM: "onSPoALeadershipTeam",
        ON_SPOA_LINKS: "onSPoALinks",
        ON_SPOA_EVENTS: "onSPoAEvents"

    }

}

function UserAutheticateCall() {
    let ErrorPopup = document.getElementsByClassName("ms-subtleEmphasis");
    if (ErrorPopup.length == 1) {
        //$('.ms-subtleEmphasis').hide();
        var redi = window.location.href;
        redi = redi.replace(/#/g, 'hash');
        window.location.href = helpers.sitecollectionurl + 'Pages/Redirect.aspx?targetUrl=' + redi;
    }
}

if (window.location.href.toLowerCase().indexOf('localhost') !== -1) {
    //Dev SP Setup
    //this only needs to be done once within your application
    setup({
        sp: {
            fetchClientFactory: () => {
                return new SPFetchClient(url, "6cac2e25-32dd-4c0f-8dd8-d1cb4cbd0883", "Zac/F9Ugg7rpaSJRnHLeLp2/2Na1E2whWbf6dCNs6pI=");
                //return new SPFetchClient(url, "47fe1d65-cf51-446a-a70e-eab3f67cd857", "qQfRxnoYDSdUuvL1xEn6NxG1qDX2B6jc+O+HYjzpMf8=");
                //return new AdalFetchClient("https://login.microsoftonline.com/574ef26f-8143-4379-bb37-8c2d44367f31", "47fe1d65-cf51-446a-a70e-eab3f67cd857", "qQfRxnoYDSdUuvL1xEn6NxG1qDX2B6jc+O+HYjzpMf8=", url);
            },
        },
    });
    window['SP_Propertyweb'] = sp.web;
}
else {
    //PROD SP Setup
    // this only needs to be done once within your application
    setup({
        sp: {
            fetchClientFactory: () => {
                return new SPRequestExecutorClient();
            }
        }
    });
    window['SP_Propertyweb'] = sp.crossDomainWeb(helpers.appweburl, helpers.hostweburl);
}


export default helpers;
